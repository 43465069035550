* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.superior {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.midel {
  background-color: #c1191a;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.titulo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  padding-left: 17%;
  padding-right: 17%;
}

.layout {
  align-items: center;
  height: 1100px;
  width: 100%;
  /*border: 4px solid black;*/
  background-image: url("./fondo/2023_03_Imagenfondo_Sin_Logo_PRO.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.formulario {
  display: grid;
  grid-template-columns: auto;
  gap: 15px;
  font-size: 16px;
  justify-content: center;
  margin: 0 auto;
  /*border: 4px solid black;*/
}

.campo-formulario {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: aliceblue;
  gap: 15px;
}

input,
select {
  height: 2rem;
  width: 20rem;
  font-size: 16px;
  margin-top: -10px;
  border-style: solid;
  border-width: 1px;
  border-color: #dfdfdf;
  text-align: left;
  padding-left: 5px;
  border-radius: 5px;
}

.boton-enviar {
  margin-top: 1rem;
  align-self: center;
  border-radius: 5px;
  background-color: #c1191a;
  color: white;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 20rem;
  height: 2rem;
  font-size: 16px;
}

.footer {
  height: 200px;
  margin-top: 0px;
  padding-left: 30%;
}

.logoLink {
  width: 8%;
}

.logoFooter {
  height: 50%;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 50px;
}

.inf-footer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: center;
}

.text-footer {
  display: flex;
  flex-direction: column;
  color: rgb(77, 77, 77);
  padding: 20px;
  gap: 10px;
  font-size: 15px;
  justify-content: center;
}

.condiciones {
  font-size: 15px;
  color: rgb(77, 77, 77);
  text-decoration: none;
}


@media (min-width: 769px) {
  .layout {
    height: 800px;
  }

  .formulario {
    grid-template-columns: auto auto;
    font-size: 20px;
    gap: 20px
  }

  input,
  select {
    width: 20rem;
    font-size: 18px;
  }

  .boton-enviar {
    width: 20rem;
    height: 2rem;
    font-size: 18px;
  }

  .footer {
    padding-left: 0%;
  }

  .logoLink {
    width: 18%;
  }

  .logoFooter {
    height: 22%;
    width: 22%;
    margin-top: 18px;
  }

  .inf-footer {
    flex-direction: row;
    margin-top: 18px;
  }

  .text-footer {
    font-size: 18px;
  }

  .condiciones {
    font-size: 20px;
  }
}


@media (min-width: 1280px) {
  .layout {
    height: 1100px;
  }

  .formulario {
    grid-template-columns: auto auto;
    font-size: 25px;
    gap: 30px
  }

  input,
  select {
    width: 30rem;
    font-size: 20px;
  }

  .boton-enviar {
    width: 30rem;
    height: 3rem;
    font-size: 20px;
  }

  .footer {
    padding-left: 0%;
  }

  .logoLink {
    width: 20%;
  }

  .logoFooter {
    height: 20%;
    width: 20%;
    margin-top: 20px;
  }

  .inf-footer {
    flex-direction: row;
    margin-top: 20px;
  }

  .text-footer {
    font-size: 20px;
  }

  .condiciones {
    font-size: 20px;
  }

}